@import "./colors.scss";

ol li {
  padding: 0.2rem 0;
}

dl {
  font-size: 1.2em;

  dt,
  dd {
    &.full-w {
      > div {
        width: 100%;
      }
    }
  }

  dd .MuiInput-underline input {
    padding-left: 0 !important;
  }

  &.compact {
    margin: 0;

    dt,
    dd {
      padding: 0;
    }

    dt {
      padding-top: 5px;
    }
  }

  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  overflow: visible;

  dt {
    flex: 0 0 20%;
    padding-top: 1em;
    text-overflow: ellipsis;
    overflow: hidden;
    border-bottom: 1px dotted #eee;
    padding: 10px 0;

    &:after {
      content: ":";
      display: inline;
    }
  }

  &.no-colons {
    dt {
      &:after {
        content: "";
        display: none;
      }
    }
  }

  dd {
    flex: 0 0 80%;
    margin-left: auto;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 10px 5% 0 10px;
    border-bottom: 1px dotted #eee;

    em {
      display: block;
      margin: 1rem 0;
      font-size: small;

      strong {
        font-weight: bold;
      }
    }
  }

  input {
    padding: 10px !important;

    &.wide {
      width: 50%;
    }
  }

  .MuiIconButton-root {
    font-size: 1.2rem;
    color: $orange1;

    &.input-clear {
      margin-left: 10px;
      margin-top: 0;
      padding: 6px;
      opacity: 0.6;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }

  .MuiFormControl-root.wide {
    // width: 50%; // TODO: what was this for?
  }

  ol {
    margin-top: 0;
    margin-left: 0;
    padding-left: 1rem;
  }
}

dd .preloader-mask.mini {
  bottom: 12px;
}

@media (min-width: 1500px) {
  dl {
    dt {
      flex: 0 0 15%;
    }

    dd {
      flex: 0 0 85%;
    }
  }
}

.multi-operation-summary {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 0.5rem;
    font-size: 14px;
    margin-bottom: 1rem;
    border-bottom: 1px dashed $orange1;

    .fas {
      font-size: 1.2rem;
      margin-left: 1rem;
    }

    .operation-summary {
      &.fail {
        span,
        .fas {
          color: rgba(192, 0, 0, 0.801);
        }
      }

      &.success {
        span,
        .fas {
          color: green;
        }
      }
    }
  }
}
