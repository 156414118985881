@import "../colors.scss";
@import "./login.scss";
@import "./account.scss";
@import "./folders.scss";
@import "./node-path.scss";
@import "./lists.scss";
@import "./release.scss";
@import "./galleries.scss";
@import "./auth.scss";
@import "./pi.scss";
@import "./courtesy-page.scss";

.headline {
  text-transform: uppercase;
}

#oticon-medical-logo {
  width: 190px;
}

#layout {
  min-width: 320px;

  > header {
    z-index: 0;
    color: #636363 !important;
    border-bottom: 1px solid $header-border-bottom-color;
    background: $header-background-color !important;
  }

  .main-toolbar {
    display: flex;
    justify-content: space-between;
  }

  .drawer {
    .MuiListItemIcon-root {
      max-width: 40px;
    }
  }

  .drawer > div {
    overflow: hidden;
  }

  header,
  main,
  .drawer,
  .drawer > div {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .drawer-toggle-btn {
    text-align: center;
    padding-top: 3px;
    height: 55px;
  }

  &.ui-drawer-open {
    > header,
    > main {
      padding-left: 220px;
    }

    .drawer > div {
      width: 220px;
    }
  }

  .MuiListItem-button {
    min-height: 48px;
  }

  #main-drawer {
    .MuiListItemIcon-root .fas {
      font-size: 1.3rem !important;
      padding-left: 2px !important;
      padding-bottom: 2px !important;
    }
  }

  &.ui-drawer-closed {
    #main-drawer {
      .MuiListItemText-root {
        display: none;
      }
    }

    .drawer > div {
      width: 60px;
    }

    > header,
    > main {
      padding-left: 60px;
    }
  }

  #content-area {
    h1 {
      margin: 0 0 0.8em 0;
    }

    padding-bottom: 10px;
  }

  .fixed-data {
    font-style: italic;
    padding: 2px 1.5rem;
    text-align: right;
    font-size: smaller;
    font-family: monospace;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100vw;
    background-color: #8282822e;

    > span {
      margin-left: 1rem;
    }

    .preloader-mask {
      float: right;

      .preloader-icon {
        top: -12px;
      }
    }
  }

  .region {
    margin-top: 40px;
    border-top: 1px dotted #dedede;
  }

  .wrapper {
    padding: 20px 0 20px 20px;
  }
}

@media (min-width: 600px) {
  #layout {
    .drawer-toggle-btn {
      height: 64px;
      padding-top: 8px;
    }
  }
}

.common-filters .preloader-mask {
  display: none;
}

.summary .edit-view {
  display: none;
}

.summary-text {
  padding-bottom: 1.2rem;
}

section {
  margin-bottom: 3rem;
}

dl.danger {
  dt {
    padding: 0;
    flex: 0 0 60%;
  }

  dd {
    flex: 0 0 20%;
  }

  .MuiCardContent-root {
    padding: 0;
  }
}

#main-menu {
  a .MuiListItemText-root > span {
    font-size: 0.9rem;
    white-space: nowrap;
  }
}

.page {
  margin: 1.5rem 2rem;

  section {
    margin-bottom: 3rem;
    &:not(:first-child) {
      border-top: 1px dotted $orange1;
    }
  }

  .dt-strong {
    dt {
      font-weight: bold;
    }
  }
}

.filters-region {
  .MuiTextField-root,
  .MuiInput-root {
    min-width: 30%;
  }
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.forget-password {
  font-size: 14px;
  color: #dc671e !important;
}

hr {
  border-top: 1px dashed #dc671e;
  margin-bottom: 1.5rem;
}

.presentation {
  margin-bottom: 2rem;
}

.hcp-role {
  white-space: nowrap;
}

.MuiTableContainer-root {
  overflow-x: inherit !important;
}
