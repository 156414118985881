#not-authorized-view {
  .bar-contents {
    padding: 1rem 0;
    margin: 0 auto 2rem auto;
    width: 80%;
    border-bottom: 1px solid #eee;
  }

  p {
    margin-bottom: 2rem;
  }

  #content-area {
    margin: 0 auto;
    width: 80%;
  }
}
