.bar-contents {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: -40px;

  img {
    margin-left: -5px;
  }

  h1 {
    padding-top: 2px;
    margin-left: 5px;
    font-family: "Montserrat", sans-serif;
    line-height: 1.4;
    display: flex;
  }

  .user {
    padding-left: 5px;
    color: #888;
  }
}

header {
  padding-left: 162px;
}
