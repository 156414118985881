@import "../colors.scss";

.gallery-card {
  max-width: 365px;
}

.release-info {
  border-bottom: 1px dashed $orange1;
  padding-bottom: 2rem;
  margin-bottom: 2rem;

  .country-flag {
    margin-right: 1rem;
  }

  .files-list {
    margin: 2rem 0;

    list-style: disc;
    padding-left: 20px;
  }
}
