.release-summary {
  > p {
    font-size: 1.2rem;
  }
}

.release-category {
  font-size: 0.8rem;
  color: #666;
  padding: 0 1rem;
}

.publish-options-region {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}
