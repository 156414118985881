.login-screen {
  height: 100vh;

  .login-banner {
    position: relative;
    background-color: #fff;

    img {
      margin-left: 1rem;
    }

    border-bottom: 5px solid #f1f1f1;

    > div {
      padding: 1rem;
      position: relative;
      display: flex;
      justify-content: space-between;
    }

    .app-name {
      color: #575754;
      margin-left: 1.2rem;
      font-size: 22px;
      white-space: nowrap;
      margin-top: 0.2rem;
      padding-top: 2px;
      margin-left: 5px;
      font-family: "Montserrat", sans-serif;
      line-height: 1.4;
      text-transform: uppercase;
    }
  }

  .login-image {
    background-image: url("./images/oticon-001.webp");
    background-repeat: no-repeat;
    background-color: #aaa;
    background-size: cover;
    background-position: right;
  }

  .login-avatar {
    margin: 0;
    box-sizing: border-box;
  }

  .login-form {
    width: 100%;
    margin-top: 8px;
    text-align: center;
    margin-top: 20%;

    button {
      margin-bottom: 2rem;
      min-width: 250px;

      &.aad-sign-in-btn {
        margin-bottom: 3rem;
      }
    }
  }
}

@media (max-width: 700px) {
  .login-banner {
    > div {
      flex-direction: column;

      .app-name {
        margin-top: 2rem !important;
      }
    }
  }
}
