$search-bar-background-color: #092b28;
$search-bar-background-focus-color: #0b2f2e;
$search-color: #829e6e;
$search-bar-border-color: #dc671e;
$search-icon-color: #829e6e;

$header-background-color: #fff;
$header-border-bottom-color: #878787;

$tabs-background-color: #ee7021;
$tabs-selected-background-color: #9e450d;
$tab-border-color: #dc671e;
$tab-underline-color: #ffef00;
$tab-button-color: #fff;
$tab-button-border-color: #9e450d;

$orange1: #ee7021;
$orange2: #dc671e;
