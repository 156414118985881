h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

.notes p,
.note {
  font-size: small;
  font-style: italic;
  color: #666;
  margin: 0.2rem 0;
  padding: 0;
  border-bottom: 1px dashed #666;
}

.small-note {
  font-size: 12px;
  color: gray;
}
