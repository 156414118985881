.files-list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding: 4px;
    margin: 0 0 10px 0;

    &:nth-child(even) {
      background-color: #fff;
    }
    &:nth-child(odd) {
      background-color: #fff5e9;
    }

    .note {
      margin-left: 5px;
    }
  }
}

.item-description {
  padding-bottom: 1rem;
}
